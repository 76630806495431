import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  private mData = {};
    
    constructor(private readonly mHTTP: HttpClient) {}
    
    public get(key:string) : string {
        return this.mData[key] || key;
    }
    
    public isDataLoaded() : boolean {
        return (Object.keys(this.mData).length > 0);
    }
    
    public setLanguage(lang: string) : Promise<void> {
        const langPath = `assets/i18n/${lang || 'en-US'}.json`;
        // Native toPromise never resolved on mobile. Custom implementation includes timeout.
        return new Promise( (resolve, reject) => {
            const mData$ = this.mHTTP.get(langPath);
            const sub = mData$.subscribe({
                next: response => {
                    this.mData = response;
                    sub.unsubscribe();
                    resolve();
                },
                error: err => {
                    sub.unsubscribe();
                    reject(err);
                }
            });
            setTimeout(() => {
                sub.unsubscribe();
                reject(new Error('XHR Request Timed Out.'))
            }, 1000 * 20);
        });
    }
}