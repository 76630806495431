import { Injectable } from '@angular/core';
import * as jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }
  /**
   * 
   * @param jwt 
   */
  setSessionAttribute(jwt):void{
    let tokenInfo=jwt_decode(jwt);
      var jwtExpiry=tokenInfo.exp;
      jwtExpiry=(jwtExpiry * 1000);
      console.log("jwtExpiry"+jwtExpiry);
      sessionStorage.setItem('jwtExpiry',jwtExpiry);
   
  }
  /**
   * 
   */
  isSessionValid():boolean{
    var jwtExpiry = Number(sessionStorage.getItem('jwtExpiry'));
   if((sessionStorage.getItem('jwtExpiry')==null || Date.now() > jwtExpiry))
   {
     sessionStorage.removeItem("jwtExpiry");
     return false;
   }
   else{
     return true;
   }
  }


}
