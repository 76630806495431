import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-dash-buttons',
  templateUrl: './dash-buttons.component.html',
  styleUrls: ['./dash-buttons.component.css']
})
export class DashButtonsComponent implements OnInit {

  constructor() { }
  @Input() leftRoute:   string;
  @Input() leftText = 'Left';
  @Input() leftIcon = 'check mark';
  @Input() rightRoute:  string;
  @Input() rightText = 'Right';
  @Input() rightIcon = 'plus';
  ngOnInit() {
  }

}
