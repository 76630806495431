import { Component, OnInit } from '@angular/core';

import {Inject} from '@angular/core';
import {HealthService } from '../health.service';
import {HealthModel} from '../health.model';
import {interval, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { Http } from '@angular/http';




@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css']
  
  
})
export class HealthComponent implements OnInit {

   applicationStatusList: Array<HealthModel>;
   applicationStatusRecord:HealthModel;
   updateSubscription: Subscription;
   errorMessage: string;
    //to indicate processsing started or not started
    processing: boolean;
    filteredArray:Array<HealthModel>;
    
  
  public filterOptions: Array<string> = ["HEALTHY", "UNKNOWN", "WARNING","UNHEALTHY"];
  public selectedFilters: Array<string>;
  
  constructor(@Inject(HealthService) private _service: HealthService,@Inject(Router) private router: Router
  ,@Inject(AuthService) private authService:AuthService,@Inject(Http) private http: Http) { }
  // Create default client
  updateSelectedFilters(event) {
    this.selectedFilters = event;
    this.filteredArray = this.applicationStatusList.filter(e =>this.selectedFilters.includes(e.status) || this.selectedFilters.length===0 );
     
  }

  ngOnInit() {
    
    this.fetchApplicationStatus();
    this.filteredArray=this.applicationStatusList;
    this.updateSubscription = interval(120000).subscribe(
      (val) => { this.updateStats()}
    );

  }
  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
}

private updateStats() {
    console.log('I am doing something every 2 minutes');
   
    this.errorMessage="";
    this.fetchApplicationStatus();
}

  
  fetchApplicationStatus():void{
    this.applicationStatusList = new Array<HealthModel>();
    this.processing = true;
       this._service.fetchApplicationHealth("https://status.nde-coresre-test.nikecloud.com/application/status",(err:any,resp:Response)=>{
       //this._service.fetchApplicationHealth("http://localhost:8080/application/status",(err:any,resp:Response)=>{
 
      let body:any
       if(err){
       console.log(err)
       this.errorMessage=err
       }
       else{
        body=resp.json();
         var items=body.applications;
         for (let record of items){
          this.applicationStatusRecord =new HealthModel(record.appName,record.status,record.statusMessage);
          this.applicationStatusList.push(this.applicationStatusRecord);
         }
        
       }
       console.log("this.applicationStatusList.length"+this.applicationStatusList.length);
       this.processing = false;
       
      })
  }
  
  

}
