import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from './translate.service'
@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(private readonly mTranslate: TranslateService) {}

  transform(key: any): any {
      return this.mTranslate.get(key);
  }

}
