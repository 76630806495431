import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HealthComponent } from './health/health.component';
import {OktaCallbackComponent,OktaLoginRedirectComponent,OktaAuthGuard} from '@okta/okta-angular/';
import { CommerceComponent } from './commerce/commerce.component';
import { PlusComponent } from './plus/plus.component';
import { HomeComponent } from './home/home.component';
import { LeadersComponentComponent } from './leaders-component/leaders-component.component';
import { OwnerServiceHealthComponent } from './owner-service-health/owner-service-health.component';


const routes: Routes = [
  {path:'',pathMatch: 'full',redirectTo: 'leaders'},
  {path:'home',component:HomeComponent,canActivate: [OktaAuthGuard]},
  {path:'core-sre',component:HealthComponent,canActivate: [OktaAuthGuard]},
  {path:'commerce',component:CommerceComponent,canActivate: [OktaAuthGuard]},
  {path:'plus',component:PlusComponent,canActivate: [OktaAuthGuard]},
  {path:'leaders',component:LeadersComponentComponent,canActivate: [OktaAuthGuard]},
  {path:'health/:owner',component:OwnerServiceHealthComponent, canActivate: [OktaAuthGuard]},

  
  { path: 'implicit/callback', component: OktaCallbackComponent },
  { path: 'login', component: OktaLoginRedirectComponent },
  { path: '**', redirectTo: 'login' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]

})
export class AppRoutingModule { }
