import { Component, OnInit, Inject } from '@angular/core';
import { Environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  constructor(@Inject(Environment) private env:Environment,@Inject(DOCUMENT) private document: any,
  @Inject(Router) private router: Router,@Inject(AuthService) private authService:AuthService){

  }

  ngOnInit() {

    console.log('router.url-->'+this.router.url);
    var index=this.router.url.indexOf("id_token");
    console.log(index);
    console.log(Environment.getOktaConfig());
    if(index!=-1){
      var endIndex=this.router.url.indexOf("&token_type");
      console.log(endIndex);
      let jwt:any = this.router.url.substring(index+9,endIndex);
      console.log(jwt);
      this.authService.setSessionAttribute(jwt);
      this.router.navigate(['/health'],{ queryParams: { internalUser: 'true' }})
      
    }
    
   else{
     console.log('in else block of login component');
     this.document.location.href = Environment.getOktaConfig(); 
      }

    
  }

}
