import { Component, OnInit } from '@angular/core';
import {Inject} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  private urlPath:string;

  constructor(@Inject(Router) private mRouter: Router) { }

  ngOnInit() {

    console.log(this.mRouter.url);
    this.urlPath=this.mRouter.url;
  }

}
