import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-segment-header',
  templateUrl: './segment-header.component.html',
  styleUrls: ['./segment-header.component.css']
})
export class SegmentHeaderComponent implements OnInit {

  constructor() { }

  @Input() text:              string | Array<string>;
  @Input() icon:              string;
  @Input() showBackLink: boolean;

  ngOnInit() {
    if (!Array.isArray(this.text)) {
      this.text = [this.text];
    }
  }

}
